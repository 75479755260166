<template>
  <div>
    <v-progress-linear indeterminate v-if="loading" />
    <v-alert type="error" prominent class="ma-12" text v-else-if="error">
      <v-row align="center" justify="center">
        <v-col class="grow">
          {{ $t("msg") }}
        </v-col>
        <v-col class="shrink">
          <v-btn
            outlined
            color="error"
            @click="$router.push({ name: 'login' })"
          >
            {{ $t("login") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
/*
see https://docs.cidaas.com/docs/cidaas-iam/1ipae577qow1b-o-auth2-flows#pkce-flow-development-perspective
*/

import { config } from "@/config";

export default {
  name: "LoginCallback",
  data: () => ({
    code: null,
    state: null,
    expires: null,
    authState: null,
    authVerifier: null,
    loadingLogin: false,
    loadingProfile: false,
    error: false,
  }),
  computed: {
    csrfError() {
      return (
        this.authState != null &&
        this.state != null &&
        this.state != this.authState
      );
    },
    redirect_uri() {
      return config.frontendUrl + "/login-callback";
    },
    loading() {
      return this.loadingLogin || this.loadingProfile;
    },
  },
  mounted() {
    this.error = false;
    this.code = this.$route.query.code;
    this.state = this.$route.query.state;
    this.expires = this.$route.query.expires_in;
    this.authState = this.$store.state.authState;
    this.authVerifier = this.$store.state.authVerifier;

    console.log(
      ">>> login callback: read state/code_verifier and redirect to login ...",
      this.authState,
      this.authVerifier
    );

    /*
    redirect from Cidaas send sometimes wrong authState
    */

    if (this.csrfError)
      this.$store.commit("setSystemError", {
        msg: this.$i18n.t("csrfMsg"),
        title: this.$i18n.t("csrfTitle"),
      });
    else this.login();
  },
  methods: {
    login() {
      var that = this;
      this.loadingLogin = true;
      this.$http
        .post(
          "login",
          {
            code: this.code,
            code_verifier: this.authVerifier,
            redirect_uri: this.redirect_uri,
          },
          { disableDefaultErrorHandler: true }
        )
        .then(function (response) {
          that.$store.commit("login", response.data);
          that.loadProfile();
        })
        .catch(function (err) {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
          that.error = true;
        })
        .finally(function () {
          that.loadingLogin = false;
          // drop auth verifier and state
          that.$store.commit("setAuthStatus", null);
          that.authState = null;
          that.authVerifier = null;
        });
    },
    loadProfile() {
      var that = this;
      this.loadingProfile = true;
      this.$http
        .get("profile")
        .then(function (response) {
          that.$store.commit("setSession", response.data);
          that.$router.push({ name: "home", replace: true });
          that.error = false;
        })
        .catch(function (err) {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
          that.error = true;
        })
        .finally(function () {
          that.loadingProfile = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
   "csrfMsg": "Invalid or missing CSRF token",
   "csrfTitle": "CSRF error",
   "msg": "An error occurred, please try logging in again.",
   "login": "Login"
  },
  "de": {
   "csrfMsg": "Ungültiger oder fehlender CSRF Token",
   "csrfTitle": "CSRF Fehler",
   "msg": "Ein Fehler ist aufgetreten, bitte probieren Sie sich erneut anzumelden.",
   "login": "Anmelden"
  }
}
</i18n>
